


















































































import Vue from "vue";
import { taskAdd, taskUpdate, taskDetails } from "@/api/setting";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "functionsList", "intervalsList"],
  data() {
    return {
      editFormInfo: {
        description: null, //描述
        interval: null, //频率
        func: null, //动作
      },
      editFormInfoRules: {
        description: [{ required: true, message: "请输入描述" }],
        interval: [
          { required: true, message: "请选择频率", trigger: "change" },
        ],
        func: [{ required: true, message: "请选择动作", trigger: "change" }],
      },
      editLoading: false,
    };
  },
  mounted() {
    if (this.baseEditInfo.id) {
      taskDetails(this.baseEditInfo.id).then((res) => {
        this.editFormInfo = this.$deepCopy(res.content);
      });
    }
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }
      this.editLoading = true;
      let obj = this.$deepCopy(this.editFormInfo);
      if (obj.id) {
        await taskUpdate(obj.id, obj)
          .then((res: any) => {
            this.$message.success("保存成功");
            this.closeFn(true);
          })
          .catch(() => {
            this.$message.warning("保存失败");
          });
      } else {
        await taskAdd(obj)
          .then((res: any) => {
            this.$message.success("保存成功");
            this.closeFn(true);
          })
          .catch(() => {
            this.$message.warning("保存失败");
          });
      }
      this.editLoading = false;
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
