
































































































import Vue from "vue";
import editForm from "./editForm.vue";
import { getTaskDicts, getTaskList, taskDelete } from "@/api/setting";
export default Vue.extend({
  components: { editForm },
  data() {
    return {
      tabsList: [{ name: "定时任务", value: "timing" }],
      tabActive: "",
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      editFormVisible: false,
      isEditFlag: false,
      baseEditInfo: {},

      functionsList: [],
      intervalsList: [],
    };
  },
  mounted() {
    getTaskDicts().then((res) => {
      if (res.code === 1 && res.content) {
        this.functionsList = res.content.functions || [];
        this.intervalsList = res.content.intervals || [];
      }
    });
    this.tabClick(this.tabsList[0]);
    this.getList();
  },
  methods: {
    tabClick(row: any) {
      if (this.tabActive != row.value) {
        this.tabActive = row.value;
      }
    },
    //common
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
      this.getStaData();
    },
    listEditFn(row, type) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        taskDelete(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.searchFn();
        });
      });
    },
    addFn() {
      this.editFormVisible = true;
      this.baseEditInfo = this.$options.data().baseEditInfo;
      this.isEditFlag = true;
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      if (flag) {
        this.getList();
      }
    },
    //业务

    getListData() {
      let obj = Object.assign({}, this.pageObj);
      getTaskList(obj).then((res) => {
        this.tableData = res.content.records || [];
        this.tableTotal = res.content.total || 0;
      });
    },
    getStaData() {
      console.log("获取统计数据");
    },
  },
});
