import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取字典数据
export const getTaskDicts = (): Promise<any> => request.get("/api/task/config");

//获取任务列表
export const getTaskList = (params: any): Promise<any> =>
  request.get("/api/task", { params });

//新增任务
export const taskAdd = (data: any): Promise<any> =>
  request.post("/api/task", data);

//修改任务
export const taskUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/task/" + id, data);

//任务删除
export const taskDelete = (id: any): Promise<any> =>
  request.delete("/api/task/" + id);

//获取任务详情
export const taskDetails = (id: any): Promise<any> =>
  request.get("/api/task/" + id);
